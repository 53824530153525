.carousel-item-login {
  max-width: 320px !important;
  min-width: 320px !important;

}

.carousel-container-login {
  width: 320px !important;
  /* overflow: visible !important; */
  transition: all 1s ease !important;
  height: fit-content !important;
  /* overflow: hidden !important; */
}


.custom-dot-list-style-login {
  display: flex;
  align-items: center;
  width: 100%;
  bottom: 30px !important;
}

.activelogin {
  width: 20px;
  height: 10px;
  background-color: #185DD2;
  border: 1px solid #185DD2;
  border-radius: 15px;
  object-fit: cover;
  margin-right: 10px;
  transition: all 0.5s ease;
  cursor: pointer;

}

.inactivelogin {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid #185DD2;
  object-fit: cover;
  margin-right: 10px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.react-multi-carousel-item {
  height: fit-content !important;
}

/* .react-multi-carousel-track{
  height: 218px !important;
} */