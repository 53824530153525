.date_select {

    /* styling */
    background-color: white;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */
    cursor: pointer;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0px;
}


/* arrows */

select.classic {
    background-image:
        linear-gradient(45deg, transparent 50%, #757575 50%),
        linear-gradient(135deg, #757575 50%, transparent 50%),
        linear-gradient(to right, white, white);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}

select.classic:focus {
    /* background-image:
        linear-gradient(45deg, white 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, white 50%),
        linear-gradient(to right, gray, gray);
    background-position:
        calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
    border-color: grey; */
    outline: 0;
}