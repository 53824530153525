.searchWrapper {
  border: none;
  box-shadow: 0px 3px 10px #0000001a;
}

.multiSelectContainer li {
  font: normal normal normal 12px Poppins;

}

.Interested {
  color: green;
  padding: 4px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 128, 0, 0.095);
}

.Callback, .RNR {
  color: goldenrod;
  padding: 4px;
  width: 100%;
  text-align: center;
  background-color: rgba(218, 165, 32, 0.112);
}

.Passive .Not_Interested {
  color: red;
  padding: 4px;
  width: 100%;
  text-align: center;
  background-color: rgba(255, 0, 0, 0.092);
}

.ant-transfer {
  text-align: left;
  justify-content: center;
  align-items: center;

}

@media (max-width:700px) {
  .ant-transfer {
    flex-direction: column;

  }
}





.label-progress-bar {
  font-size: 10px;
  color: white;
}