.otp-input {
    width: 72px !important;
    height: 50px !important;
    border: 1px solid #185dd2;
    border-radius: 8px;
    box-shadow: 0px 3px 10px #0000001a;
    margin-right: 20px;
    border: none;
    outline: none;
    text-align: center;
    font: normal normal 600 16px/25px Poppins;
}

@media (min-width: 1920px) {
    .otp-input {
        width: 92px !important;
        height: 70px !important;
    }

}

#error {
    display: none;
}