.job_multi_select {
    font-size: 12px !important;
    width: 100% !important;
    border: none !important;
    align-items: center !important;
    z-index: 99999 !important;
}

.job_multi_select .dropdown-heading {
    height: 30px !important;
}