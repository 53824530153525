.global_filter_multi_select {
    font-size: 12px !important;
    width: 100% !important;
    border: none !important;
    align-items: center !important;
    color: black !important;
}

.global_filter_multi_select .dropdown-heading {
    height: 30px !important;
}